import * as React from "react"
import { RichText } from 'prismic-reactjs'
import styled from "styled-components"

// Components & Utils
import Layout from "../layout/Layout"
import Seo from '../utils/Seo'
import LinkPrismic from '../utils/LinkPrismic'

// STYLE
const SimplePageContainer = styled.div`
  padding-top: 140px;
  padding-bottom: 60px;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }

  p {
    margin-bottom: 0.4em;
  }
`

const SimplePage = ({ pageContext }) => {
  return (
    <Layout>
      <Seo pageTitle={pageContext.title} />
      <SimplePageContainer className="container">
        <h1>{pageContext.title}</h1>
        <RichText render={pageContext.data.page_text.raw} serializeHyperlink={LinkPrismic} />
      </SimplePageContainer>
    </Layout>
  )
}


export default SimplePage